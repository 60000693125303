<template>
	<div class="wrap">
		<myHead></myHead>
		<div class="wrap_body">
			<div class="center">
				<div class="ucenter_main">
					<ucMenu num="4"></ucMenu>
					<div class="right_con">
						<div class="wallet_main">
							<div class="ye_wrap">
								<p class="fb">我的可用余额</p>
								<p class="balance"><em>{{wallet_money_d}}</em>.{{wallet_money_x}}</p>
								<div class="btn_wrap">
									<p class="btn" @click.self="is_chongzhi = true">立即充值</p>
									<p class="btn" @click.self="is_tixian = true">提现</p>
								</div>
							</div>
							<div class="dk_wrap">
								<p>
									<i class="iconfont icon-suo"></i>
									抵扣金:<em>{{wallet_deduction}}</em>
									<i class="iconfont icon-shuoming" @click="$router.push('./notice?type=2&id=1')"></i>
								</p>
								<p>
									<i class="iconfont icon-suo"></i>
									待解冻抵扣金:<em>{{wallet_etc_deduction}}</em>
									<i class="iconfont icon-shuoming" @click="$router.push('./notice?type=2&id=2')"></i>
								</p>
							</div>
						</div>
						<div class="record_main">
							<p class="fb">交易记录</p>
							<div class="screen_wrap">
								<div class="left_tabs">
									<p class="tab" :class="recordType==''?'active':''" @click="changeType('')"><span>全部</span></p>
									<p class="tab" :class="recordType==1?'active':''" @click="changeType('1')"><span>充值</span></p>
									<p class="tab" :class="recordType==2?'active':''" @click="changeType('2')"><span>采购</span></p>
									<p class="tab" :class="recordType==3?'active':''" @click="changeType('3')"><span>退款</span></p>
									<p class="tab" :class="recordType==4?'active':''" @click="changeType('4')"><span>提现</span></p>
									<!-- <p class="tab" :class="recordType==10?'active':''" @click="changeType('10')"><span>罚款</span></p> -->
									<p class="tab" :class="recordType==11?'active':''" @click="changeType('11')"><span>其他</span></p>
								</div>
								<div class="right_time">
									<p class="txt">日期：</p>
									<el-date-picker
									      v-model="rangeTime"
									      type="datetimerange"
									      range-separator="-"
									      start-placeholder="开始日期"
									      end-placeholder="结束日期"
									      :clearable="false"
									      format="yyyy-MM-dd HH:mm">
									</el-date-picker>
									<el-button plain size="medium" @click="timeCheck()">查询</el-button>
								</div>
							</div>
							<div class="table_wrap">
								<el-table :data="tableData" style="width: 100%">
									<el-table-column prop="createtime" label="交易时间" width="300">
									</el-table-column>
									<el-table-column prop="order_no" label="订单编号">
									</el-table-column>
									<el-table-column prop="typename" label="类型">
									</el-table-column>
									<el-table-column prop="scope" label="金额（元）">
										<template slot-scope="scope">
											<span :style="scope.row.money>0?'':'color: var(--red);'">{{scope.row.money}}</span>
										</template>
									</el-table-column>
									<el-table-column prop="msg" label="说明">
									</el-table-column>
								</el-table>
							</div>
							<div class="turn_page">
								<el-pagination
								      :page-size="page_size"
								      layout="total,prev, pager, next, jumper"
								      prev-text="上一页"
								      next-text="下一页"
								      :total="listTotal"
								      hide-on-single-page
								      :current-page="page"
								      @current-change="currentChange">
								</el-pagination>
							</div>
						</div>
					</div>
				</div>
				<el-dialog class="chongzhi_mask" :visible.sync="is_chongzhi" width="400px">
					<div class="chongzhi_main">
						<p class="title"><img class="icon" src="../../assets/images/zhifubao.png" />支付宝扫码充值</p>
						<div class="input_line">
							<p class="txt">订单号：</p>
							<el-input class="in" placeholder="输入支付订单编号" v-model="paymentNum"></el-input>
						</div>
						<img class="code" :src="paymentCodeImg" />
						<div class="btn_wrap">
							<p class="btn" @click.self="is_chongzhi = false">取消</p>
							<p class="btn" @click="bindRecharge()">确定</p>
						</div>
					</div>
				</el-dialog>
				<el-dialog class="tixian_mask" :visible.sync="is_tixian" width="400px">
					<div class="tixian_main">
						<p class="title"><i class="iconfont icon-qian"></i>余额提现<span class="t">（可提现金额 <em>￥{{tixian_able}}</em>）</span></p>
						<div class="tx_count">
							<input v-model="input_tixian" class="in" placeholder="输入提现金额" />
							<p class="tx" @click="input_tixian = tixian_able">全部提现</p>
						</div>
						<el-input v-model="input_zfbName" placeholder="输入支付宝名称" class="el_input el_in2" @input="checkInput">
							<p slot="prefix" class="txts">支付宝名称</p>
						</el-input>
						<el-input v-model="input_zfbMember" placeholder="输入支付宝账号" class="el_input el_in2" @input="checkInput">
							<p slot="prefix" class="txts">支付宝账号</p>
						</el-input>
						<el-input :value="phone" placeholder="输入手机号码" class="el_input el_in2" readonly v-if="has_zfb">
							<p slot="prefix" class="txts">手机号码</p>
						</el-input>
						<el-input v-model="phoneCode" placeholder="输入短信验证码" class="el_input el_in2" v-if="has_zfb">
							<p slot="prefix" class="txts">验证码</p>
							<!-- <p slot="suffix" class="getcode">获取验证码</p> -->
							<p slot="suffix" class="getcode" @click="getPhoneCode('4')">{{is_get_phone_code?phone_second+'s重新获取':'获取验证码'}}</p>
						</el-input>
						<div class="btn_wrap">
							<p class="btn" @click.self="is_tixian = false">取消</p>
							<p class="btn" @click="bindTixian">确定</p>
						</div>
					</div>
				</el-dialog>
			</div>
		</div>
		<myFoot></myFoot>
	</div>
</template>

<script>
import Vue from 'vue';
import { mapActions,mapState,mapMutations } from "vuex";
import myHead from '../../components/myHead/myhead';
import myFoot from '../../components/myFoot/myfoot';
import ucMenu from '../../components/ucenterMenu/ucmenu';
import common from '../../assets/js/utils/common';
export default {
	name: "ucenterWallet",
	data() {
		return {
			rangeTime:"",
			startTime:"",
			endTime:"",
			is_chongzhi:false,
			is_tixian:false,
			tableData: [],
			wallet_money_d:"0",
			wallet_money_x:"00",
			wallet_deduction:"0.00",
			wallet_etc_deduction:"0.00",
			paymentCodeImg:"",
			paymentNum:"",
			is_payment:false,
			is_doTixian:false,
			tixian_able:"0.00",
			input_tixian:"",
			zfbMember:"",
			zfbName:"",
			input_zfbMember:"",
			input_zfbName:"",
			phone:"",
			phoneCode:"",
			has_zfb:false,
			is_get_phone_code:false,
			phone_second:60,
			recordType:"",
			page:1,
			page_size:10,
			listTotal:0,
		}
	},
	components:{
		myHead,myFoot,ucMenu
	},
	computed:{
		...mapState({
			userinfo:(state)=>state.login.userinfo,
		}),
	},
	created(){
		this.phone = this.userinfo.mobile
		console.log(this.userinfo)
		this.getPaymentCode({data:{},success:(res)=>{
			if(res.code===200){
				console.log('get payment code',res)
				this.paymentCodeImg = res.data.msg
			} else {
				this.$message({message: res.msg,type: 'error',});
			}
		}})
		this.getWalletInfo()
		this.getRecordData()
	},
	methods: {
		...mapActions({
			getMoneyData:"ucenter/getMoneyData",
			getPaymentCode:"ucenter/getPaymentCode",
			getCheckRecharge:"ucenter/getCheckRecharge",
			getTixianInfo:"ucenter/getTixianInfo",
			doTixian:"ucenter/doTixian",
			getCodeData:"login/getCodeData",
			getWallteData:"ucenter/getWallteData"
		}),
		getWalletInfo(){//获取钱包数据，可提现数据
			this.getMoneyData({data:{},success:(res)=>{
				if(res.code===200){
					console.log('get money data',res)
					this.wallet_money_d = parseInt(res.data.money)
					this.wallet_money_x = res.data.money.slice(-2)
					this.wallet_deduction = res.data.deduction
					this.wallet_etc_deduction = res.data.stay_thaw
				} else {
					this.$message({message: res.msg,type: 'error',});
				}
			}})
			this.getTixianInfo({data:{},success:(res)=>{
				if(res.code===200){
					console.log('get tixian info',res)
					this.tixian_able = res.data.money
					this.zfbMember = res.data.zfbMember
					this.input_zfbMember = res.data.zfbMember?res.data.zfbMember:""
					this.zfbName = res.data.zfbName
					this.input_zfbName = res.data.zfbName?res.data.zfbName:""
					this.has_zfb = res.data.zfbMember?false:true
				} else {
					this.$message({message: res.msg,type: 'error',});
				}
			}})
		},
		bindRecharge(){//充值查询
			if(!this.paymentNum){
				this.$message({message: '请输入支付订单编号',type: 'error',});
				return false
			}
			if(!this.is_payment){
				this.is_payment = true
				this.getCheckRecharge({data:{
					trade_no:this.paymentNum
				},success:(res)=>{
					if(res.code===200){
						console.log('get payment code',res)
						this.$message({message: res.msg,type: 'success',});
						setTimeout(()=>{
							this.is_payment = false
							this.paymentNum = ""
							this.is_chongzhi = false
							this.getWalletInfo()
							this.getRecordData()
						},500)
					} else {
						this.is_payment = false
						this.$message({message: res.msg,type: 'error',});
					}
				}})
			}
		},
		bindTixian(){//提现
			if(!this.input_tixian){
				this.$message({message: '请输入提现金额',type: 'error',});
				return false
			}
			if(!this.input_zfbName){
				this.$message({message: '请输入支付宝名称',type: 'error',});
				return false
			}
			if(!this.input_zfbMember){
				this.$message({message: '请输入支付宝账号',type: 'error',});
				return false
			}
			if(this.has_zfb && !this.phoneCode){
				this.$message({message: '请输入短信验证码',type: 'error',});
				return false
			}
			if(!this.is_doTixian){
				this.is_doTixian = true
				this.doTixian({data:{
						money:this.input_tixian,
						zfbName:this.input_zfbName,
						zfbMember:this.input_zfbMember,
						mobile:this.phone,
						code:this.phoneCode,
						type:this.has_zfb?'1':'2'
					},success:(res)=>{
					if(res.code===200){
						console.log('do tixian',res)
						this.$message({message: res.msg,type: 'success',});
						setTimeout(()=>{
							this.is_tixian = false
							this.phoneCode = ""
							this.is_doTixian = false
							this.getWalletInfo()
						},500)
					} else {
						this.$message({message: res.msg,type: 'error',});
						this.is_doTixian = false
					}
				}})
			}
		},
		checkInput(val){//监听输入的支付宝账户是否与上一次提现的账户一致
			if(this.input_zfbName == this.zfbName && this.input_zfbMember == this.zfbMember && this.zfbName != '' && this.zfbMember != ''){
				this.has_zfb = false
			}else{
				this.has_zfb = true
			}
		},
		getPhoneCode(ev){//获取短信验证码
			let phone = this.phone
			if(!this.is_get_phone_code){
				this.is_get_phone_code = true
				this.getCodeData({data:{
						mobile:phone,
						event:ev,
					},success:(res)=>{
					if(res.code===200){
						console.log('get phone code',res)
						this.$message({message: '发送成功~',type: 'success',});
						let timer = setInterval(()=>{
							this.phone_second -= 1
							if(this.phone_second == 0){
								clearInterval(timer)
								this.phone_second = 60
								this.is_get_phone_code = false
							}
						},1000)
					} else {
						this.$message({message: res.msg,type: 'error',});
						this.is_get_phone_code = false
					}
				}})
			}
		},
		getRecordData(){//获取钱包记录流水
			this.getWallteData({data:{
				type:this.recordType,
				page:this.page,
				pagesize:this.page_size,
				start_time:this.startTime,
				end_time:this.endTime
			},success:(res)=>{
				if(res.code===200){
					console.log('get wallet record list',res)
					this.tableData = res.data.data
					this.listTotal = res.data.total
				} else {
					this.$message({message: res.msg,type: 'error',});
				}
			}})
		},
		timeCheck(){//日期查询
			this.startTime = this.rangeTime?this.DateToStr(this.rangeTime[0]):''
			this.endTime = this.rangeTime?this.DateToStr(this.rangeTime[1]):''
			this.getRecordData()
		},
		DateToStr(date){
			let year = date.getFullYear();//年
			let month = date.getMonth();//月
			let day = date.getDate();//日
			let hours = date.getHours();//时
			let min = date.getMinutes();//分
			let second = date.getSeconds();//秒
			return year + "-" +
				((month + 1) > 9 ? (month + 1) : "0" + (month + 1)) + "-" +
				(day > 9 ? day : ("0" + day)) + " " +
				(hours > 9 ? hours : ("0" + hours)) + ":" +
				(min > 9 ? min : ("0" + min)) + ":" +
				(second > 9 ? second : ("0" + second));
		},
		changeType(val){
			this.recordType = val
			this.page = 1
			this.getRecordData()
		},
		currentChange(val){
			this.page = val
			this.getRecordData()
			console.log(val)
		}
	}
}
</script>

<style scoped>
.wrap .wrap_body{display: flex;flex-direction: column;}
.center{display: flex;flex: 1;}
.ucenter_main{padding: 30px 0 40px;display: flex;justify-content: space-between;flex: 1;}
.right_con{width: 980px;}
.wallet_main{padding: 0 40px 36px;background: #fff;display: flex;}
.wallet_main .ye_wrap{margin-top: 36px;}
.wallet_main .ye_wrap .balance{margin-top: 30px;font-weight: bold;font-size: 30px;}
.wallet_main .ye_wrap .balance>em{font-size: 50px;}
.wallet_main .ye_wrap .btn_wrap{display: flex;margin-top: 40px;}
.wallet_main .ye_wrap .btn_wrap .btn{height: 40px;border-radius: 6px;display: flex;justify-content: center;align-items: center;font-size: 14px;font-weight: bold;box-sizing: border-box;cursor: pointer;}
.wallet_main .ye_wrap .btn_wrap .btn:nth-child(1){width: 135px;background: #f0413e;color: #fff;margin-right: 15px;}
.wallet_main .ye_wrap .btn_wrap .btn:nth-child(2){width: 115px;background: #fff;color: #f0413e;border: 1px solid #f0413e;}
.wallet_main .dk_wrap{margin-left: 180px;margin-top: 76px;}
.wallet_main .dk_wrap>p .icon-suo{font-size: 16px;color: #333;margin-right: 12px;}
.wallet_main .dk_wrap>p>em{font-size: 18px;font-weight: bold;margin-left: 8px;}
.wallet_main .dk_wrap>p .icon-shuoming{font-size: 16px;color: #558ed5;margin-left: 8px;cursor: pointer;}
.wallet_main .dk_wrap>p:nth-child(2){margin-top: 18px;}
.record_main{margin-top: 16px;padding: 30px 40px 40px;background: #fff;line-height: 1;}
.record_main .screen_wrap{margin-top: 12px;height: 50px;border-bottom: 1px solid #eee;display: flex;justify-content: space-between;}
.screen_wrap .left_tabs{display: flex;height: 100%;width: 50%;}.screen_wrap .left_tabs .tab{height: 100%;position: relative;cursor: pointer;display: flex;align-items: center;flex: 1;justify-content: center;}
.screen_wrap .left_tabs .tab>span{font-size: 14px;color: #333333;}
.screen_wrap .left_tabs .tab.active::after{content: '';width: 100%;height: 3px;background: var(--red);position: absolute;left: 0;bottom: 0;z-index: 2;}
.screen_wrap .left_tabs .tab.active>span{color: var(--red);font-weight: bold;}
.screen_wrap .left_tabs .tab:last-child>span{border-right: none;}
.screen_wrap .right_time{display: flex;justify-content: flex-end;align-items: center;}
.screen_wrap .right_time .txt{font-size: 14px;color: #666666;margin: 0 10px;display: flex;justify-content: center;align-items: center;}
.turn_page{display: flex;justify-content: flex-end;margin-top: 30px;}
.tixian_main{box-sizing: border-box;background: #fff;}
.tixian_main .icon-guanbi{font-size: 12px;color: #999;position: absolute;top: 16px;right: 16px;cursor: pointer;z-index: 222;}
.tixian_main .title{font-size: 20px;line-height: 1;}
.tixian_main .title .icon-qian{margin-right: 8px;font-size: 24px;color: #f38f00;}
.tixian_main .title .t{font-size: 12px;color: #666666;}
.tixian_main .title .t>em{color: var(--red);}
.tixian_main .tx_count{margin: 24px 0 6px;height: 80px;background: #f5f5f5;border-radius: 4px;box-sizing: border-box;padding: 0 18px;display: flex;align-items: center;}
.tixian_main .tx_count .in{font-size: 30px;color: #333;font-weight: bold;flex: 1;width: 0;}
.tixian_main .tx_count .in::placeholder{font-size: 16px;color: #cccccc;line-height: 34px;}
.tixian_main .tx_count .tx{font-size: 12px;color: #f0413e;margin-left: 18px;cursor: pointer;}
.tixian_main .input_line{margin: 10px 0 0;height: 36px;box-sizing: border-box;border: 1px solid #ddd;border-radius: 4px;display: flex;padding: 0 10px;align-items: center;}
.tixian_main .input_line .txt{width: 74px;font-size: 12px;color: #999999;}
.tixian_main .input_line .in{flex: 1;width: 0;font-size: 12px;color: #333;}
.tixian_main .input_line .getcode{padding-left: 8px;border-left: 1px solid #eee;font-size: 12px;color: #333333;cursor: pointer;line-height: 1;}
.tixian_main .el_in2{margin: 14px 0 0;}
.tixian_main .btn_wrap{margin: 36px 0 0;display: flex;justify-content: space-between;}
.tixian_main .btn_wrap .btn{width: 160px;height: 40px;box-sizing: border-box;border-radius: 6px;display: flex;justify-content: center;align-items: center;font-size: 14px;cursor: pointer;}
.tixian_main .btn_wrap .btn:nth-child(1){border: 1px solid #dddddd;color: #333;}
.tixian_main .btn_wrap .btn:nth-child(2){background: #f0413e;color: #fff;}
.chongzhi_main{box-sizing: border-box;background: #fff;}
.chongzhi_main .icon-guanbi{font-size: 12px;color: #999;position: absolute;top: 16px;right: 16px;cursor: pointer;z-index: 222;}
.chongzhi_main .title{display: flex;align-items: center;margin: 0 22px;font-size: 20px;line-height: 1;}
.chongzhi_main .title .icon{width: 22px;height: 22px;margin-right: 8px;}
.chongzhi_main .input_line{margin: 15px 22px 0;display: flex;align-items: center;}
.chongzhi_main .input_line .txt{font-size: 12px;color: #666666;margin-right: 10px;}
.chongzhi_main .input_line .in{width: auto;}
.chongzhi_main .code{width: 155px;height: auto;display: block;margin: 30px auto 0;}
.chongzhi_main .btn_wrap{margin: 45px 0 0;display: flex;justify-content: space-between;}
.chongzhi_main .btn_wrap .btn{width: 160px;height: 40px;box-sizing: border-box;border-radius: 6px;display: flex;justify-content: center;align-items: center;font-size: 14px;cursor: pointer;}
.chongzhi_main .btn_wrap .btn:nth-child(1){border: 1px solid #dddddd;color: #333;}
.chongzhi_main .btn_wrap .btn:nth-child(2){background: #f0413e;color: #fff;}

.el-date-editor{margin-top: unset !important;}
</style>
<style>

.tixian_mask .el-dialog__body{padding-top: 0;}
.el_input{display: flex;margin: 14px 32px 0;width: initial;}
.el_input .el-input__inner{height: 38px;line-height: 38px;padding-left: 58px;flex: 1;width: 0;}
.el_input .el-input__inner:focus{border-color: var(--red);}
.el_input.el_in2 .el-input__inner{padding-left: 100px;}
.el_input .el-input__prefix{left: 2px;top: 1px}
.el_input .el-input__prefix .icons{width: 38px;height: 36px;display: flex;justify-content: center;align-items: center;top: 1px;left: 2px;background: #f5f5f5;border-right: 1px solid #ddd;border-radius: 4px 0 0 4px;}
.el_input .el-input__prefix .icons .iconfont{font-size: 20px;color: #cccccc;}
.el_input .el-input__prefix .txts{width: 100%;height: 100%;display: flex;align-items: center;padding-left: 10px;}
.el_input .el-input__suffix{display: flex;}
.el_input .el-input__suffix .el-input__suffix-inner{display: flex;align-items: center;}
.el_input .el-input__suffix .el-input__suffix-inner .getcode{font-size: 14px;color: #333333;padding: 0 12px;border-left: 1px solid #ccc;line-height: 1;cursor: pointer;}

.screen_wrap .right_time .el-input__inner{height: 36px;line-height: 36px;font-size: 12px;padding: 0 8px;border-radius: 4px;width: auto;margin-right: 14px;}
.screen_wrap .right_time .el-date-editor .el-range__icon{color: #666;line-height: 36px;}
.screen_wrap .right_time .el-range-editor .el-range-input{width: 120px;line-height: 36px;color: #333;}
.screen_wrap .right_time .el-date-editor .el-range-separator{color: #666;line-height: 36px;font-size: 12px;}
.screen_wrap .right_time .el-date-editor .el-range__close-icon{line-height: 36px;display: none;}
.el-button--text{padding: 7px 15px !important;}
.el-button--text:hover{border-color: var(--red);color: var(--red);}
.el-table .el-table__cell{text-align: center;padding: 14px 0;}
.el-table thead .el-table__cell{padding: 3px 0;background: #f9f9f9;color: #666;border-bottom: none !important;}
.chongzhi_main .input_line .in .el-input__inner{width: 250px;height: 30px;box-sizing: border-box;padding: 0 10px;font-size: 14px;color: #333;}

.el-button{color: #333;border-color: #ddd;}
.el-button--small{padding: 7px 15px;}
.el-checkbox{color: #666;display: flex;align-items: center;}
.el-checkbox__inner{border-color: #ddd;width: 16px;height: 16px;}
.el-checkbox__input{display: flex;}
.el-checkbox__label{font-size: 12px;line-height: 1;}
.el-checkbox__inner::after{height: 9px;left: 5px;}
.el-checkbox__input.is-indeterminate .el-checkbox__inner::before{height: 4px;}

/*red border color*/
.el-button.is-plain:focus, .el-button.is-plain:hover ,.el-checkbox__inner:hover ,.el-checkbox__input.is-focus .el-checkbox__inner,.el-checkbox__input.is-checked .el-checkbox__inner, .el-checkbox__input.is-indeterminate .el-checkbox__inner, .el-pagination__jump .el-input__inner:focus ,.el-input__inner:focus ,.el-range-editor.is-active, .el-range-editor.is-active:hover{border-color: var(--red);}

/*red color*/
.el-button.is-plain:focus, .el-button.is-plain:hover, .el-checkbox__input.is-checked+.el-checkbox__label, .el-pager li.active ,.el-pagination button:hover ,.el-pager li:hover ,.el-date-table td.today span ,.el-date-table td.available:hover ,.el-time-panel__btn.confirm{color: var(--red);}

/*red background color*/
.el-checkbox__input.is-checked .el-checkbox__inner, .el-checkbox__input.is-indeterminate .el-checkbox__inner ,.el-date-table td.end-date span, .el-date-table td.start-date span{background-color: var(--red);}

</style>
